import React from "react";

function RearEnd() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="122"
            height="70"
            fill="none"
            viewBox="0 0 122 70"
        >
            <mask
                id="mask0_0_322"
                style={{ maskType: "alpha" }}
                width="122"
                height="70"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H121.25V70H0z"></path>
            </mask>
            <g fill="#396B94" mask="url(#mask0_0_322)">
                <path d="M102.5 56.873c0 .344.441.625.979.625h5.542c.539 0 .979-.281.979-.625s-.44-.625-.979-.625h-5.542c-.538 0-.979.281-.979.625zM70.013 36.809c-.287 2.838.16 3.45.2 5.82-1.041.182-1.862.727-2.32 1.541-.508.9-.523 2.061-.045 3.274l1.44 3.622c1.59 4.3 3.62 4.755 5.583 5.2.17.04 4.676 1.055 5.865 1.222a1.009 1.009 0 001.138-.864 1.008 1.008 0 00-.862-1.137c-1.122-.156-5.517-1.152-5.698-1.192-1.823-.414-2.925-.662-4.144-3.956l-1.444-3.648c-.247-.626-.277-1.172-.07-1.53.2-.36.623-.496.945-.552.962-.156 1.652-.94 1.642-1.864-.035-2.405-.207-5.987-.207-5.987 0-1.692 1.365-3.178 3.046-3.314l8.2-.667c.987-.081 2.473-.455 3.374-.849l12.615-5.512c2.065-.904 5.337-1.753 7.295-1.9a1.01 1.01 0 00-.146-2.016c-2.436.182-5.955 1.193-7.949 2.062l-12.616 5.512c-.705.308-1.958.626-2.733.687l-8.196.667c-2.703.217-4.898 2.612-4.898 5.38h-.016z"></path>
                <path d="M93.753 33.699c-.04.712.361 1.333.904 1.385l11.795 1.164h.073c.508 0 .933-.517.972-1.197.04-.712-.361-1.333-.903-1.385l-11.796-1.164c-.537-.052-1.006.478-1.045 1.197zM91.875 64.998c5.167 0 9.375-4.208 9.375-9.375s-4.208-9.375-9.375-9.375-9.375 4.208-9.375 9.375c0 5.168 4.208 9.375 9.375 9.375zm0-16.697a7.33 7.33 0 017.323 7.322 7.33 7.33 0 01-7.323 7.323 7.33 7.33 0 01-7.322-7.323 7.33 7.33 0 017.322-7.322zM17.274 57.498h15.452c.563 0 1.024-.281 1.024-.625s-.46-.625-1.024-.625H17.274c-.563 0-1.024.281-1.024.625s.461.625 1.024.625zM12.056 25.742c2.1.404 5.244 1.75 6.958 2.763l10.724 6.364c.847.504 2.266 1.053 3.223 1.252l23.342 4.913c1.73.364 3.334 1.79 3.505 3.092l.26 2.35c.1.927.858 1.566 1.565 1.79 1.097.35 1.594 1.382 1.263 2.634l-.391 1.496c-.441 1.686-2.291 3.107-4.04 3.107H55.12c-.551 0-1.002.449-1.002.998 0 .548.45.997 1.002.997h3.344c2.677 0 5.304-2.02 5.981-4.603l.391-1.497c.597-2.29-.471-4.359-2.597-5.037-.09-.03-.17-.105-.175-.105l-.261-2.374c-.28-2.155-2.512-4.27-5.078-4.808L33.377 34.16c-.752-.16-1.945-.624-2.612-1.018L20.047 26.78c-1.9-1.127-5.264-2.564-7.605-3.012a.997.997 0 10-.376 1.96l-.01.015z"></path>
                <path d="M23.953 37.498c.535 0 .99-.499 1.043-1.158.052-.704-.372-1.325-.943-1.395l-10.406-1.19c-.587-.07-1.09.454-1.143 1.158-.052.704.373 1.325.944 1.395l10.405 1.19h.1zM43.75 64.998c4.823 0 8.75-4.208 8.75-9.375s-3.927-9.375-8.75-9.375S35 50.456 35 55.623c0 5.168 3.922 9.375 8.75 9.375zm0-16.697c3.77 0 6.834 3.284 6.834 7.322 0 4.039-3.065 7.323-6.834 7.323-3.769 0-6.834-3.284-6.834-7.323 0-4.038 3.065-7.322 6.834-7.322zM44.646 34.492c.188.325.527.506.871.506a.99.99 0 00.516-.145c.481-.289.638-.924.355-1.415l-2.39-4.113 5.928-.294c.294-.016.567-.16.75-.398.182-.238.253-.543.197-.837l-1.746-9.067 7.416 5.197a.999.999 0 001.478-.387L64.99 9.47l2.071 15.732c.04.31.218.584.481.75.263.16.582.19.87.087l7.675-2.852-1.65 4.857a1.036 1.036 0 00.628 1.312 1.006 1.006 0 001.285-.635l2.344-6.913c.127-.377.03-.796-.248-1.075a.994.994 0 00-1.058-.232l-8.494 3.157-2.34-17.763c-.06-.45-.399-.806-.834-.883-.436-.073-.871.15-1.074.553l-7.922 15.99-8.51-5.957a.992.992 0 00-1.129-.01 1.04 1.04 0 00-.435 1.064l2 10.38-6.44.32a1 1 0 00-.84.542c-.167.32-.162.703.02 1.018l3.25 5.585.005-.005z"></path>
            </g>
        </svg>
    );
}

export default RearEnd;
