import React from "react";

function Sideswipe() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="122"
            height="70"
            fill="none"
            viewBox="0 0 122 70"
        >
            <mask
                style={{ maskType: "alpha" }}
                width="122"
                height="70"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H121.25V70H0z"></path>
            </mask>
            <g fill="#396B94" clipPath="url(#clip0_0_378)">
                <path d="M7.94 63.748h4.193a2.47 2.47 0 002.46-2.473V58.78h33.365v2.495a2.47 2.47 0 002.459 2.473h4.192a2.47 2.47 0 002.46-2.473v-2.664a2.477 2.477 0 001.57-2.304v-1.356c0-1.05-.65-1.944-1.57-2.304v-8.02c0-2.7-1.668-5.017-4.016-5.968l-5.086-8.731c-1.487-2.549-4.945-4.541-7.882-4.541H22.836c-2.928 0-6.426 1.966-7.96 4.47l-5.405 8.81a6.447 6.447 0 00-3.994 5.96v8.02A2.474 2.474 0 003.91 54.95v1.356c0 1.045.65 1.944 1.566 2.304v2.664a2.47 2.47 0 002.459 2.473h.004zm-2.26-8.797c0-.383.31-.694.69-.694h49.81c.38 0 .69.311.69.694v1.356c0 .383-.31.694-.69.694H6.37a.693.693 0 01-.69-.694v-1.356zm34.365-2.473H22.51v-1.78c0-1.04.84-1.885 1.875-1.885h13.79c1.035 0 1.876.845 1.876 1.886v1.779h-.005zM12.827 61.28c0 .382-.31.694-.69.694H7.945a.693.693 0 01-.69-.694v-2.495h5.577v2.495h-.005zm41.787.694H50.42a.693.693 0 01-.69-.694v-2.495h5.577v2.495c0 .382-.31.694-.69.694h-.004zM16.379 30.786c1.203-1.966 4.162-3.625 6.457-3.625h17.25c2.286 0 5.196 1.677 6.355 3.66l4.294 7.366H11.877h-.036l4.538-7.405v.004zm-4.502 9.18h38.792c2.552 0 4.63 2.09 4.63 4.657v7.846H41.81v-1.78c0-2.018-1.631-3.664-3.643-3.664h-13.79c-2.009 0-3.645 1.641-3.645 3.665v1.779H7.246v-7.846c0-2.566 2.079-4.657 4.63-4.657z"></path>
                <path d="M3.891 38.89l2.662.867a.886.886 0 001.115-.57.893.893 0 00-.57-1.12L4.434 37.2a.23.23 0 01-.146-.12.223.223 0 010-.187l.318-.823c.102-.267.5-.538.783-.538h4.675a.89.89 0 000-1.78H5.39c-1.021 0-2.065.721-2.432 1.678l-.314.822a2.012 2.012 0 00.053 1.593c.239.502.663.876 1.198 1.05l-.004-.005zM58.117 37.2l-2.663.867a.892.892 0 00-.57 1.12.88.88 0 001.114.57l2.663-.867c.535-.174.96-.547 1.194-1.05a1.994 1.994 0 00.053-1.592l-.314-.823c-.367-.956-1.416-1.677-2.433-1.677h-4.675a.89.89 0 000 1.78h4.675c.283 0 .681.27.783.537l.319.823a.24.24 0 010 .187.22.22 0 01-.142.12l-.004.005zM82.787 25.382c-2.928 0-6.426 1.966-7.96 4.47l-5.405 8.81a6.447 6.447 0 00-3.994 5.96v8.02a2.474 2.474 0 00-1.566 2.304v1.356c0 1.046.65 1.944 1.566 2.304v2.664a2.47 2.47 0 002.459 2.473h4.193a2.47 2.47 0 002.459-2.473v-2.495h33.365v2.495a2.47 2.47 0 002.459 2.473h4.193a2.47 2.47 0 002.459-2.473v-2.664a2.473 2.473 0 001.566-2.304v-1.356c0-1.05-.65-1.944-1.566-2.304v-8.02c0-2.7-1.667-5.016-4.016-5.968l-5.09-8.731c-1.486-2.549-4.945-4.541-7.882-4.541H82.778h.01zm17.205 27.09H82.456v-1.778c0-1.04.84-1.886 1.875-1.886h13.79c1.035 0 1.875.845 1.875 1.886v1.779h-.004zm16.139 1.78c.38 0 .69.311.69.694v1.356c0 .383-.31.694-.69.694h-49.81a.693.693 0 01-.69-.694v-1.356c0-.383.31-.694.69-.694h49.81zm-43.357 7.023c0 .383-.31.694-.69.694h-4.193a.693.693 0 01-.69-.694V58.78h5.578v2.495h-.005zm41.787.694h-4.193a.692.692 0 01-.69-.694V58.78h5.577v2.495c0 .383-.31.694-.69.694h-.004zm.69-17.342v7.846h-13.49v-1.78c0-2.018-1.632-3.664-3.644-3.664h-13.79c-2.008 0-3.645 1.641-3.645 3.665v1.779h-13.49v-7.846c0-2.566 2.08-4.657 4.631-4.657h38.793c2.551 0 4.63 2.09 4.63 4.657h.005zm-8.864-13.801l4.295 7.365H71.828h-.036l4.543-7.405c1.203-1.966 4.161-3.625 6.457-3.625h17.249c2.286 0 5.196 1.677 6.355 3.66l-.009.005z"></path>
                <path d="M64.554 36.07c.102-.267.5-.538.783-.538h4.674a.89.89 0 000-1.78h-4.674c-1.022 0-2.066.721-2.433 1.678l-.314.823a2.012 2.012 0 00.053 1.592c.239.502.663.876 1.199 1.05l2.662.867a.886.886 0 001.115-.57.892.892 0 00-.57-1.12l-2.663-.868a.231.231 0 01-.146-.12.223.223 0 010-.187l.314-.822v-.005zM112.433 35.528h4.675c.283 0 .681.271.783.538l.318.823a.24.24 0 010 .186.218.218 0 01-.141.12l-2.663.868a.893.893 0 00-.57 1.12.88.88 0 001.114.57l2.667-.867c.536-.174.96-.547 1.195-1.05a2 2 0 00.053-1.592l-.314-.823c-.368-.956-1.416-1.677-2.433-1.677h-4.675a.89.89 0 000 1.78l-.009.004zM69.36 5.901a.885.885 0 00-1.605-.53L59.918 16.47l-5.785-5.426a.885.885 0 00-1.486.694l.358 7.979-4.874-.494a.886.886 0 00-.906 1.219l1.862 4.608a.883.883 0 00.818.556.893.893 0 00.818-1.228l-1.309-3.242 4.427.449a.887.887 0 00.973-.925l-.305-6.832 4.94 4.635a.901.901 0 00.695.235.895.895 0 00.632-.369l6.771-9.59-.185 11.84c0 .272.115.525.318.699a.88.88 0 00.743.187l6.02-1.228-1.721 3.305a.892.892 0 00.372 1.2.882.882 0 001.194-.373l2.587-4.964a.896.896 0 00-.075-.947.883.883 0 00-.885-.338l-6.767 1.379.217-13.601-.004.004z"></path>
            </g>
            <defs>
                <clipPath id="clip0_0_378">
                    <path
                        fill="#fff"
                        d="M0 0H117.5V58.75H0z"
                        transform="translate(2.5 4.998)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Sideswipe;
