import React from "react";

function Intersection() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="122"
            height="70"
            fill="none"
            viewBox="0 0 122 70"
        >
            <mask
                style={{ maskType: "alpha" }}
                width="122"
                height="70"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H121.25V70H0z"></path>
            </mask>
            <g fill="#396B94" clipPath="url(#clip0_0_346)">
                <path d="M41.225 52.75a.961.961 0 00-.964-.953H20.642a.84.84 0 01-.843-.834v-1.53c0-.462.38-.838.843-.838h19.62c.53 0 .963-.429.963-.953a.961.961 0 00-.964-.953H21.597v-8.911c0-2.926 2.41-5.31 5.368-5.31h23.62c.53 0 .963-.428.963-.952a.961.961 0 00-.964-.953H26.965c-.048 0-.091.005-.14.005l5.263-8.44c1.393-2.23 4.805-4.117 7.455-4.117h19.802c2.641 0 6.005 1.906 7.34 4.16l1.836 3.093a.971.971 0 001.32.338.948.948 0 00.343-1.306l-1.837-3.092c-1.696-2.86-5.652-5.104-9.002-5.104H39.543c-3.344 0-7.34 2.206-9.098 5.023l-6.164 9.883c-.014.024-.024.048-.039.076-2.68 1.068-4.583 3.665-4.583 6.69v9.088a2.742 2.742 0 00-1.797 2.564v1.53c0 1.172.752 2.173 1.797 2.563v3.046c0 1.51 1.244 2.74 2.771 2.74h4.815c1.527 0 2.77-1.23 2.77-2.74v-2.87h10.232c.53 0 .964-.428.964-.952l.014.01zm-13.122 3.822a.84.84 0 01-.844.834h-4.814a.84.84 0 01-.843-.834v-2.869h6.505v2.87h-.004z"></path>
                <path d="M25.76 38.102c-1.335-.143-2.42.853-2.42 2.144v1.358c0 1.235.969 2.302 2.207 2.435l7.808.844c.086.01.168.014.25.014a2.138 2.138 0 002.164-2.158V41.38c0-1.234-.969-2.301-2.207-2.435l-7.807-.843h.005zm8.086 3.278v1.358c0 .158-.091.253-.236.253l-7.85-.843c-.256-.029-.492-.286-.492-.539v-1.358c0-.157.087-.248.275-.248h.01l7.806.844a.58.58 0 01.487.538v-.005zM21.168 32.284a.964.964 0 00.916-.658.953.953 0 00-.622-1.2l-3.055-.978a.318.318 0 01-.198-.162.298.298 0 010-.252l.362-.93c.125-.324.597-.643.95-.643h5.368c.53 0 .964-.429.964-.953a.961.961 0 00-.964-.953h-5.374c-1.151 0-2.332.8-2.747 1.863l-.361.925a2.188 2.188 0 00.058 1.758c.265.553.742.968 1.344 1.158l3.056.977a.913.913 0 00.299.048h.004zM101.579 57.549V48.46c0-3.035-1.909-5.632-4.598-6.695l-5.85-9.874c-1.697-2.86-5.653-5.104-9.002-5.104H62.327c-3.345 0-7.34 2.207-9.099 5.023l-6.212 9.964c-2.675 1.073-4.569 3.665-4.569 6.686v9.088a2.742 2.742 0 00-1.797 2.564v1.53c0 1.172.752 2.172 1.797 2.563v3.045c0 1.51 1.244 2.745 2.771 2.745h4.815c1.527 0 2.77-1.23 2.77-2.745v-2.869h38.41v2.87c0 1.51 1.243 2.744 2.77 2.744h4.815c1.528 0 2.771-1.23 2.771-2.745v-3.045a2.742 2.742 0 001.798-2.564v-1.53c0-1.172-.752-2.172-1.798-2.563h.01zm-.13 4.093a.84.84 0 01-.844.834H43.421a.84.84 0 01-.843-.834v-1.53a.84.84 0 01.843-.833h57.184a.84.84 0 01.844.834v1.53zm-19.32-4.274H61.893v-2.059c0-1.2.988-2.178 2.202-2.178h15.831c1.215 0 2.203.977 2.203 2.178v2.059zM54.866 32.807c1.393-2.23 4.805-4.117 7.456-4.117h19.802c2.64 0 6.005 1.906 7.34 4.16l4.978 8.396c-.058 0-.111-.01-.164-.01h-44.54c-.047 0-.09.006-.139.006l5.263-8.44.005.005zm-5.127 10.34h44.539c2.959 0 5.368 2.384 5.368 5.31v8.91H84.052V55.31c0-2.254-1.851-4.084-4.13-4.084H64.09c-2.28 0-4.13 1.835-4.13 4.084v2.059H44.37v-8.912c0-2.925 2.41-5.308 5.369-5.308zm1.137 24.104c0 .462-.38.839-.843.839h-4.815a.84.84 0 01-.843-.839v-2.869h6.506v2.87h-.005zm47.927.839h-4.814a.84.84 0 01-.844-.839v-2.869h6.506v2.87c0 .461-.38.838-.843.838h-.005z"></path>
                <path d="M48.33 54.718l7.808.844c.087.01.169.014.25.014a2.142 2.142 0 002.169-2.159V52.06c0-1.23-.969-2.301-2.207-2.435l-7.807-.843c-1.335-.143-2.42.853-2.42 2.144v1.358c0 1.234.97 2.302 2.208 2.435zm-.279-3.793c0-.157.082-.248.275-.248h.01l7.807.844c.255.028.491.286.491.538v1.358c0 .158-.091.253-.24.253l-7.851-.843c-.256-.029-.492-.286-.492-.539v-1.363zM87.643 55.572c.082 0 .164 0 .25-.014l7.807-.844c1.239-.133 2.208-1.2 2.208-2.435v-1.358c0-1.291-1.1-2.287-2.42-2.144l-7.807.843c-1.238.134-2.207 1.201-2.207 2.435v1.358c0 1.21.95 2.159 2.169 2.159zm-.241-3.512c0-.253.231-.51.491-.538l7.85-.844c.15 0 .237.095.237.253v1.358a.572.572 0 01-.492.538l-7.807.844h-.043c-.15 0-.241-.09-.241-.248v-1.358l.005-.005zM41.35 38.783c.124-.324.597-.643.949-.643h5.368c.53 0 .964-.429.964-.953a.961.961 0 00-.964-.953H42.3c-1.152 0-2.333.8-2.747 1.863l-.362.93a2.188 2.188 0 00.058 1.758c.265.553.742.967 1.345 1.158l3.06.977a.964.964 0 001.214-.61.953.953 0 00-.621-1.201l-3.056-.977a.318.318 0 01-.197-.162.298.298 0 010-.253l.361-.929-.005-.005zM104.48 38.092c-.414-1.062-1.595-1.863-2.747-1.863h-5.368a.961.961 0 00-.964.953c0 .524.433.953.964.953h5.368c.352 0 .819.32.949.644l.362.929a.323.323 0 010 .252c-.024.057-.082.124-.198.162l-3.06.977a.953.953 0 00-.622 1.201.958.958 0 001.215.61l3.06-.977a2.241 2.241 0 001.345-1.158 2.187 2.187 0 00.057-1.758l-.361-.93v.005zM95.195 2.905a.97.97 0 00-1.132-.143l-13.629 7.5-3.344-8.41a.962.962 0 00-.887-.606h-.014a.963.963 0 00-.887.582l-3.58 8.358-4.859-2.916a.976.976 0 00-.949-.029.954.954 0 00-.516.786l-.337 5.61a.954.954 0 00.906 1.005.962.962 0 001.022-.896l.24-4.027 4.444 2.669a.972.972 0 001.388-.443l3.094-7.225 2.882 7.253a.941.941 0 00.573.548c.26.09.55.072.795-.067l11.88-6.538-6.092 12.119a.944.944 0 00.626 1.349l6.95 1.72-3.499 2.592a.949.949 0 00-.193 1.334.967.967 0 001.35.19l5.118-3.792a.948.948 0 00.366-.953.962.962 0 00-.708-.739l-7.75-1.92 6.94-13.81a.95.95 0 00-.198-1.116v.015z"></path>
            </g>
            <defs>
                <clipPath id="clip0_0_346">
                    <path
                        fill="#fff"
                        d="M0 0H88.75V68.75H0z"
                        transform="translate(16.25 1.246)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Intersection;
