import React from "react";

function Rollover() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="122"
            height="70"
            fill="none"
            viewBox="0 0 122 70"
        >
            <mask
                style={{ maskType: "alpha" }}
                width="122"
                height="70"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H121.25V70H0z"></path>
            </mask>
            <g fill="#396B94" clipPath="url(#clip0_0_369)">
                <path d="M38.024 11.515c.22.216.531.327.837.3.311-.026.596-.179.784-.427l3.016-4.021 1.712 3.503c.257.528.896.745 1.433.497.537-.254.757-.883.504-1.411l-2.485-5.083a1.067 1.067 0 00-.87-.592 1.083 1.083 0 00-.965.423l-3.333 4.449-6.5-6.34a1.087 1.087 0 00-1.175-.223c-.402.17-.66.56-.655.988l.13 8.967-15.506-1.828a1.078 1.078 0 00-1.106.618c-.187.423-.08.92.274 1.226L25.846 22.7l-6.392 4.628c-.333.243-.494.65-.419 1.051.08.402.381.719.784.824l6.247 1.596c.091.021.183.032.268.032.478 0 .913-.317 1.036-.793a1.054 1.054 0 00-.767-1.289l-4.063-1.036 5.651-4.09c.263-.19.424-.485.435-.802a1.033 1.033 0 00-.365-.84l-10.133-8.766 13.294 1.564c.306.037.617-.058.848-.264.231-.206.36-.497.354-.798l-.112-7.582 5.506 5.379h.006z"></path>
                <path d="M102.197 42.007V31.239c1.24-.439 2.13-1.606 2.13-2.98v-1.807c0-1.374-.89-2.542-2.13-2.98v-3.64c0-1.75-1.444-3.17-3.215-3.17h-5.705c-1.777 0-3.22 1.42-3.22 3.17v3.45H48.554v-3.45c0-1.75-1.444-3.17-3.22-3.17h-5.706c-1.776 0-3.22 1.42-3.22 3.17v3.64c-1.24.438-2.13 1.606-2.13 2.98v1.807c0 1.368.89 2.536 2.13 2.98v10.768c0 3.545 2.238 6.584 5.394 7.836l7.374 11.772c2.072 3.302 6.779 5.891 10.724 5.891h19.315c3.95 0 8.614-2.631 10.61-5.986l6.946-11.667c3.172-1.241 5.42-4.285 5.42-7.846h.006zM59.32 31.429h19.97v2.5c0 1.452-1.202 2.636-2.678 2.636H62.003c-1.475 0-2.678-1.184-2.678-2.637V31.43h-.005zm-21.822-2.113c-.59 0-1.074-.47-1.074-1.057v-1.807c0-.581.483-1.057 1.074-1.057h63.615c.59 0 1.068.47 1.068 1.057v1.807c0 .581-.478 1.057-1.068 1.057H37.498zm54.7-9.48c0-.58.478-1.056 1.074-1.056h5.705c.59 0 1.068.47 1.068 1.057v3.45h-7.847v-3.45zm-52.57-1.056h5.706c.59 0 1.073.47 1.073 1.057v3.45H38.56v-3.45c0-.582.478-1.057 1.074-1.057h-.005zm-1.073 23.227V31.429h18.613v2.5c0 2.62 2.168 4.75 4.825 4.75h14.609c2.662 0 4.825-2.13 4.825-4.75v-2.5h18.612v10.578c0 3.492-2.882 6.33-6.43 6.33H44.99c-3.547 0-6.43-2.843-6.43-6.33h-.005zM87.97 60.452c-1.594 2.68-5.603 4.94-8.759 4.94H59.894c-3.161 0-7.234-2.24-8.893-4.887L44.695 50.44c.096 0 .193.016.29.016h48.63c.107 0 .215-.01.317-.016l-5.963 10.018v-.006z"></path>
                <path d="M55.36 37.759v-1.601c0-1.485-1.266-2.621-2.79-2.463l-9.253.994c-1.433.153-2.555 1.39-2.555 2.805v1.601c0 1.39 1.1 2.478 2.501 2.478.097 0 .193 0 .29-.015l9.253-.994c1.433-.153 2.554-1.39 2.554-2.805zm-12.038 1.696c-.236.02-.408-.117-.408-.36v-1.6c0-.339.3-.666.644-.703l9.253-.994h.059c.215 0 .349.138.349.365v1.6c0 .334-.306.672-.644.704l-9.248.993-.005-.005zM85.807 40.57l9.253.992c.096.011.193.016.29.016 1.4 0 2.5-1.088 2.5-2.478v-1.6c0-1.422-1.121-2.653-2.554-2.806l-9.253-.994c-1.546-.158-2.79.978-2.79 2.462v1.602c0 1.415 1.12 2.652 2.554 2.805zm-.408-4.407c0-.222.134-.365.349-.365h.059l9.253.993c.343.037.644.365.644.703v1.601c0 .243-.172.386-.408.36l-9.253-.994c-.338-.037-.644-.375-.644-.703v-1.6.005zM42.52 54.25h-6.36c-.445 0-1.024-.392-1.185-.804l-.43-1.094a.437.437 0 01.006-.354.45.45 0 01.273-.227l3.623-1.152c.564-.18.875-.776.692-1.331a1.077 1.077 0 00-1.352-.682l-3.623 1.157a2.565 2.565 0 00-1.55 1.326 2.505 2.505 0 00-.07 2.024l.429 1.094c.483 1.226 1.852 2.15 3.188 2.15h6.36c.59 0 1.073-.475 1.073-1.056 0-.582-.483-1.057-1.073-1.057v.005zM104.445 49.768l-3.623-1.157a1.077 1.077 0 00-1.352.682 1.056 1.056 0 00.692 1.331l3.623 1.152a.451.451 0 01.274.227.436.436 0 01.005.354l-.429 1.1c-.156.4-.746.797-1.186.797h-6.366c-.59 0-1.073.476-1.073 1.057 0 .58.483 1.056 1.073 1.056h6.366c1.336 0 2.71-.924 3.188-2.15l.429-1.094a2.506 2.506 0 00-.07-2.023 2.547 2.547 0 00-1.551-1.327v-.005z"></path>
            </g>
            <defs>
                <clipPath id="clip0_0_369">
                    <path
                        fill="#fff"
                        d="M0 0H92.5V65H0z"
                        transform="translate(13.75 2.506)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Rollover;
